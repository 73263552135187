import { Controller } from "@hotwired/stimulus";
import { post, put } from "@rails/request.js";

export default class extends Controller {
    connect() {
        this.chatIdValue = document.getElementById('user-area--main-section--right-sidebar-chat-files-form').getAttribute('data-chat-id');
    }

    async submit(e) {
        e.preventDefault(); // Prevent the default form submission

        let form = e.currentTarget.querySelector('form');

        // Check if the form element is correctly identified
        if (!form) {
            console.error("Form element not found");
            return;
        }

        // Create a FormData object from the form
        let formData = new FormData(form);

        // Convert FormData to nested JSON
        let formDataJson = {};
        formData.forEach((value, key) => {
            const keys = key.split('[').map(k => k.replace(']', ''));
            keys.reduce((acc, cur, i) => {
                if (i === keys.length - 1) {
                    acc[cur] = value;
                } else {
                    acc[cur] = acc[cur] || {};
                }
                return acc[cur];
            }, formDataJson);
        });

        // Log the formDataJson to check its structure
        console.log("FormData JSON:", formDataJson);

        if (this.chatIdValue !== null) {
            await put(`/user_area/chats/${this.chatIdValue}`, {
                responseKind: "turbo-stream",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ chat: formDataJson.chat })
            });
        } else {
            await post(`/user_area/chats`, {
                responseKind: "turbo-stream",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ chat: formDataJson.chat })
            });
        }
    }
}
