import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        document.addEventListener("onModalBackdropClick", this.closeModal.bind(this));
    }
    closeModal() {
        let modalWrapper = document.getElementById('user-area--navigation--navbar--modal-wrapper')
        modalWrapper.children[0].remove()
        document.getElementById('user-area--chat-files-modal').classList.remove('invisible', 'pointer-events-none')
    }
}
