import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs';
import {post, put, destroy} from '@rails/request.js'

export default class extends Controller {
    connect() {
        document.addEventListener("closeChatSettings", this.closeRightSidebar.bind(this))
    }

    toggleRightSidebar() {
        let rightSidebar = document.getElementById('user-area--chat-files-wrapper')
        let arrowOpen = document.getElementById('user-area--chat-files-arrow-open')
        let arrowClose = document.getElementById('user-area--chat-files-arrow-close')
        if(window.getComputedStyle(arrowOpen.parentElement).display === 'none') {
            rightSidebar.classList.toggle("hidden");
            if(rightSidebar.classList.contains('hidden')) {
                document.getElementById('new-chat-widget').classList.remove('fade-out')
                document.getElementById('new-chat-widget').classList.add('fade-in')
            } else {
                document.getElementById('new-chat-widget').classList.remove('fade-in')
                document.getElementById('new-chat-widget').classList.add('fade-out')
            }
        } else {
            rightSidebar.classList.toggle("closed");
            arrowOpen.classList.toggle('hidden')
            arrowClose.classList.toggle('hidden')
            if(rightSidebar.classList.contains('closed')) {
                document.getElementById('new-chat-widget').classList.remove('fade-out')
                document.getElementById('new-chat-widget').classList.add('fade-in')
            } else {
                document.getElementById('new-chat-widget').classList.remove('fade-in')
                document.getElementById('new-chat-widget').classList.add('fade-out')
            }
        }
    }

    closeRightSidebar() {
        let rightSidebar = document.getElementById('user-area--chat-files-wrapper')
        let arrowOpen = document.getElementById('user-area--chat-files-arrow-open')
        let arrowClose = document.getElementById('user-area--chat-files-arrow-close')
        rightSidebar.classList.add("closed");
        arrowOpen.classList.add('hidden')
        arrowClose.classList.remove('hidden')
    }
}
