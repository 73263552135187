import { Controller } from "@hotwired/stimulus";
import { marked } from "marked";
import hljs from "highlight.js";

export default class extends Controller {
    static values = { messageId: Number }

    connect() {
        let element = document.getElementById(`user-area--main-section--chat-message--${this.messageIdValue}--span`);
        this.markdownText(element);
    }

    markdownText(element) {
        const preprocessTextForMarkdown = (text) => {
            // Step 1: Split the text by code blocks
            const codeBlockRegex = /```[\s\S]*?```/g;
            const parts = text.split(codeBlockRegex);
            const codeBlocks = text.match(codeBlockRegex) || [];

            // Step 2: Replace newlines outside code blocks with spans
            const processedParts = parts.map(part => {
                return part
                    .replace(/\n{2,}/g, '\n<span class="h-7 flex flex-1 w-full"></span>\n')
                    .replace(/\n/g, '\n<span class="flex flex-1 w-full"></span>\n');
            });

            // Step 3: Reconstruct the text with code blocks intact
            let reconstructedText = "";
            for (let i = 0; i < processedParts.length; i++) {
                reconstructedText += processedParts[i];
                if (i < codeBlocks.length) {
                    reconstructedText += codeBlocks[i];
                }
            }

            return reconstructedText;
        };

        const markdownText = element.innerText || "";
        const preprocessedText = preprocessTextForMarkdown(markdownText);

        // Step 4: Parse the preprocessed markdown to HTML
        const html = marked.parse(preprocessedText);
        element.innerHTML = html;

        // Step 5: Highlight code blocks
        element.querySelectorAll("pre code").forEach((block) => {
            hljs.highlightElement(block);
            block.parentNode.classList.add('rounded-xl', 'overflow-x-auto');
        });

        element.classList.remove('whitespace-pre-wrap');
    }
}
