import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["toolTipContent"]
    connect() {
        this.disableFormElements();
        this.disableLinks()
    }

    disableFormElements() {
        this.toolTipContentTarget.querySelectorAll('button, input, textarea, select').forEach(el => {
            el.setAttribute('disabled', '');
        });
    }

    disableLinks() {
        this.toolTipContentTarget.querySelectorAll('a, div').forEach(el => {
            el.addEventListener('click', this.preventLinkAction);
            el.classList.add('pointer-events-none', 'opacity-50'); // Tailwind classes to visually disable the link
        });
    }

}
