import { Controller } from "@hotwired/stimulus"
import {get, put, destroy} from '@rails/request.js'

export default class extends Controller {
    static values = { chatId: Number}

    async unpin(event) {
        event.preventDefault()
        await put(
            `/user_area/chats/${this.chatIdValue}`,  {
                responseKind: "turbo-stream",
                body: JSON.stringify({
                    chat: {
                        is_pinned: false
                    }
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
    }
    async pin(event) {
        event.preventDefault()
        await put(
            `/user_area/chats/${this.chatIdValue}`,  {
                responseKind: "turbo-stream",
                body: JSON.stringify({
                    chat: {
                        is_pinned: true
                    }
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
    }

    async rename(event) {
        event.preventDefault()
        await get(
            `/user_area/chats/${this.chatIdValue}/edit`,  {
                responseKind: "turbo-stream"
            }
        )
    }

    async delete(event) {
        event.preventDefault()
        await destroy(`/user_area/chats/${this.chatIdValue}`,  {
            responseKind: "turbo-stream"
        })
    }
}
