import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition";

export default class extends Controller {
    static targets = ["dropdownContainer", "dropdownMenu", "selectButton", "arrowUp", "arrowDown"]

    connect() {
        this.outsideClickListener = this.outsideClick.bind(this);
        document.addEventListener("sideBarScrollDetected", this.handleScroll.bind(this)); // gets signal from chats stimulus controller when div is scrolled
    }

    toggleDropdown() {
        this.selected_style = this.selectButtonTarget.getAttribute('data-selected-style').split(' ')
        this.showDropdown()
    }

    showDropdown() {
        let existingDropdown = document.getElementById('active-dropdown');
        if(existingDropdown) return

        this.isSelected();
        let dropdownContent = this.dropdownMenuTarget.cloneNode(true)
        let element = document.body.appendChild(dropdownContent);

        const buttonRect = this.selectButtonTarget.getBoundingClientRect();
        const top = buttonRect.bottom + window.scrollY;
        const left = buttonRect.left + window.scrollX;

        element.style.position = 'absolute';
        element.style.zIndex = '1000';
        element.style.top = `${top}px`;
        element.style.left = `${left}px`;
        element.classList.remove("hidden");
        element.id = 'active-dropdown'
        enter(element);

        document.addEventListener('click', this.outsideClickListener);
        this.dropdownVisible = true; // Track that the dropdown is visible
    }

    hideDropdown() {
        this.isNotSelected();

        let element = document.getElementById('active-dropdown');

        if (element) {
            leave(element);
            document.body.removeChild(element);
        }
        document.removeEventListener('click', this.outsideClickListener);
        this.dropdownVisible = false; // Track that the dropdown is hidden
    }

    handleScroll(event) {
        if (!this.dropdownVisible) return;

        const dropdown = document.getElementById('active-dropdown');
        if (!dropdown) return;
        this.hideDropdown()
    }


    outsideClick(event) {
        let element = document.getElementById('active-dropdown')
        if (element) {
            if (element !== event.target && !this.element.contains(event.target)) {
                this.hideDropdown()
            }
        }
    }

    isSelected() {
        for (const style in this.selected_style) {
            this.selectButtonTarget.classList.add(this.selected_style[style])
        }
        this.toggleArrows()
    }

    isNotSelected() {
        for (const style in this.selected_style) {
            this.selectButtonTarget.classList.remove(this.selected_style[style])
        }
        this.toggleArrows()
    }

    toggleArrows() {
        if (this.hasArrowUpTarget && this.hasArrowDownTarget) {
            if (this.arrowUpTarget.classList.contains('hidden')) {
                this.arrowDownTarget.classList.add('hidden')
                this.arrowUpTarget.classList.remove('hidden')
            } else {
                this.arrowDownTarget.classList.remove('hidden')
                this.arrowUpTarget.classList.add('hidden')
            }
        }
    }

    disconnect() {
        document.removeEventListener('click', this.outsideClickListener);
        document.removeEventListener("sideBarScrollDetected", this.handleScroll.bind(this));
    }
}
