import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["scrollDiv"];
    connect() {
        this.scrollDivTarget.addEventListener('scroll', () => {
            const containerRect = this.scrollDivTarget.getBoundingClientRect();
            const scrollEvent = new CustomEvent("sideBarScrollDetected", {
                detail: { containerRect },
                bubbles: true, // This makes the event bubble up through the DOM
                cancelable: true,
            });

            // Dispatch the event on the scrollableDivTarget so it bubbles up through the DOM
            this.scrollDivTarget.dispatchEvent(scrollEvent);
        });
    }
}
