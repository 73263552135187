import { Controller } from "@hotwired/stimulus"
import { put } from "@rails/request.js"

export default class extends Controller {
    connect() {
        this.userIdValue = document.getElementById('user-area--settings--language-form').getAttribute('data-user-id')
    }
    closeModal() {
        const element = document.getElementById('user-area--navigation--navbar--modal-wrapper')
        element.remove()
        document.getElementById('user-area--chat-files-modal').classList.remove('invisible', 'pointer-events-none')
    }

    async onSelect(e) {
        let id = e.currentTarget.id
        let parts = id.split('_')

        const response = await put(`/user_area/settings/language`, {
            responseKind: "html",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user: {
                    language: parts[parts.length - 1]
                }
            })
        })

        if(response.ok) {
            Turbo.visit('/user_area/main')
        }
    }
}
