import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["formContainer", "closeIcon", "openIcon", "chatsContainer"];
    connect() {
        document.addEventListener('click', this.outsideClick.bind(this))
    }

    outsideClick(event) {
        if (!this.element.contains(event.target) && this.hasFormContainerTarget) {
            this.submitForm(event)
        }
    }

    submitForm(event) {
        event.preventDefault(); // Prevent the default form submission

        // Find the first form inside the target container
        const firstForm = this.formContainerTarget.querySelector('form');
        if (!firstForm) return; // If no form is found, exit the function
        firstForm.requestSubmit()
    }

    toggle(event) {
        if(this.hasChatsContainerTarget) {
            this.chatsContainerTarget.classList.toggle('hidden')
            this.closeIconTargets.forEach((element) => element.classList.toggle('hidden'))
            this.openIconTargets.forEach((element) => element.classList.toggle('hidden'))
        }

    }


}
