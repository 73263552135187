import { Controller } from "@hotwired/stimulus"
import { put } from '@rails/request.js';

export default class extends Controller {
    async submitForm() {
        let profilePictureValue = document.getElementById('user_profile_picture').value

        await put(`/user_area/settings/account/`, {
            responseKind: "turbo-stream",
            body: JSON.stringify({
                user: {
                    profile_picture: profilePictureValue
                }
            })
        });
    }
}
