import { Controller } from "@hotwired/stimulus"
import consumer from "@/channels/consumer"
import { get, post } from '@rails/request.js'
import { leave } from "el-transition";
import { marked } from "marked"
import hljs from "highlight.js"

export default class extends Controller {
    static targets = ["formContainer", "messageTextArea"]
    static values = { isUserMessage: Boolean, chatId: Number, identificationNr: String }

    connect() {
        this.userIsScrolling = false
        this.messageWrapper = document.getElementById('user-area--main--chat-messages-wrapper');
        this.messageWrapper.addEventListener('scroll', () => {
            // Determine the direction of the scroll
            let currentScrollPosition = this.messageWrapper.scrollTop;
            this.userIsScrolling = currentScrollPosition < this.lastScrollTop;
            this.lastScrollTop = currentScrollPosition <= 0 ? 0 : currentScrollPosition; // For Mobile or negative scrolling
        });

        this.chatId = this.chatIdValue
        this.identificationNrs = []
        this.channel = consumer.subscriptions.create({ channel: "ChatMessageChannel", chat_id: this.chatId }, {
            connected: async (data) => {
                if (this.identificationNrValue.length !== 0 && !this.identificationNrs.includes(this.identificationNrValue)) {
                    this.identificationNrs.push(this.identificationNrValue)
                    const response = await get(`/user_area/chat_messages?chat_id=${this.chatId}&id=${this.identificationNrValue}`, { responseKind: "turbo-stream" })
                    this.scrollToNewestMessage()
                    console.log(this.chatId)

                    if (response.ok) {
                        await post(`/user_area/chats/${this.chatId}/chat_response_job`, {
                            responseKind: "html",
                            body: {
                                chat_id: this.chatId.toString(),
                                rand: this.identificationNrValue
                            }
                        });
                    }
                }
            },
            received: async (data) => {
                if (data.type === 'update') {
                    let span = document.getElementById(`user-area--main-section--chat-message--${data.id}--span`)
                    span.classList.add('whitespace-pre-wrap')
                    if (span) {
                        span.innerText = data.message
                        this.markdownText(span)
                        this.scrollToNewestMessageDynamic()
                    }
                }
                if (data.type === 'done') {
                    await get(`/user_area/chat_messages/${data.chat_message_id}`, { responseKind: "turbo-stream" })
                }
            }
        });

        this.textArea = this.messageTextAreaTarget.querySelector('textarea')
        this.textArea.value = localStorage.getItem("inputValue") || "";
        this.textArea.overflow = 'hidden'
    }

    markdownText(element) {
        const preprocessTextForMarkdown = (text) => {
            // Step 1: Split the text by code blocks
            const codeBlockRegex = /```[\s\S]*?```/g;
            const parts = text.split(codeBlockRegex);
            const codeBlocks = text.match(codeBlockRegex) || [];

            // Step 2: Replace newlines outside code blocks with spans
            const processedParts = parts.map(part => {
                return part
                    .replace(/\n{2,}/g, '\n<span class="h-7 flex flex-1 w-full"></span>\n')
                    .replace(/\n/g, '\n<span class="flex flex-1 w-full"></span>\n');
            });

            // Step 3: Reconstruct the text with code blocks intact
            let reconstructedText = "";
            for (let i = 0; i < processedParts.length; i++) {
                reconstructedText += processedParts[i];
                if (i < codeBlocks.length) {
                    reconstructedText += codeBlocks[i];
                }
            }

            return reconstructedText;
        };

        const markdownText = element.innerText || "";
        const preprocessedText = preprocessTextForMarkdown(markdownText);

        // Step 4: Parse the preprocessed markdown to HTML
        const html = marked.parse(preprocessedText);
        element.innerHTML = html;

        // Step 5: Highlight code blocks
        element.querySelectorAll("pre code").forEach((block) => {
            hljs.highlightElement(block);
            block.parentNode.classList.add('rounded-xl', 'overflow-x-auto');
        });

        element.classList.remove('whitespace-pre-wrap');
    }

    scrollToNewestMessage() {
        this.messageWrapper.scrollTo({
            top: this.messageWrapper.scrollHeight,
            behavior: 'smooth'
        });
    }

    scrollToNewestMessageDynamic() {
        // Don't auto-scroll if the user is currently scrolling
        const isNearBottom = this.messageWrapper.scrollHeight - this.messageWrapper.scrollTop - this.messageWrapper.clientHeight < 200; // 100px threshold

        if (!this.userIsScrolling && isNearBottom) {
            this.messageWrapper.scrollTo(0, this.messageWrapper.scrollHeight);
        }
    }

    onInput() {
        localStorage.setItem("inputValue", this.textArea.value);

        this.textArea.style.overflowY = 'hidden';
        this.textArea.style.height = 'auto';

        let desiredHeight = this.textArea.scrollHeight + 4;
        let maxHeight = document.getElementById('user-area--main--content-wrapper').clientHeight;

        if (desiredHeight > maxHeight - 16) {
            desiredHeight = maxHeight - 16;
            this.textArea.style.overflowY = 'auto';
        } else {
            this.textArea.style.overflowY = 'hidden';
        }

        this.textArea.style.height = `${desiredHeight}px`;
    }


    onKeyDown(event) {
        if (event.key === "Enter" && !event.shiftKey && !this.isUserMessageValue) {
            event.preventDefault() // Prevent newline
            this.submitForm(event) // Submit the form
        }
    }


    async submitForm(event) {
        if (this.isUserMessageValue) return;
        event.preventDefault(); // Prevent the default form submission

        const newChatWidget = document.getElementById('new--chat--example--widget')
        leave(newChatWidget).then(() => {
            document.getElementById('existing--chat-messages-wrapper').remove()
        })

        let chatId = document.getElementById('user-area--main--content-wrapper').getAttribute('data-chat-id');
        if (chatId === null) {
            const response = await post(`/user_area/chats/`, {
                responseKind: "json", // Change this to 'json' to indicate you expect a JSON response
                headers: {
                    'Content-Type': 'application/json'
                },
                body: {
                    title: this.textArea.value.substring(0, 30)
                }
            });

            if (response.ok) {
                const data = await response.response.json();
                chatId = data.chat_id;

                await get(`/user_area/chats/${chatId}?only_chat=true`, { responseKind: "turbo-stream" });
            } else {
                console.error('Failed to create chat', response.statusText);
            }
        }

        await post(`/user_area/chat_messages/`, {
            responseKind: "turbo-stream",
            body: {
                chat_id: chatId,
                chat_message: {
                    message: this.textArea.value
                }
            }
        });
        this.textArea.value = '' //clear input field
        this.onInput(event)
    }


    async cancelResponse(event) {
        if (!this.isUserMessageValue) return;
        event.preventDefault(); // Prevent the default form submission

        let chatId = document.getElementById('user-area--main--content-wrapper').getAttribute('data-chat-id');

        await post(`/user_area/chat_cancellations/`, {
            responseKind: "turbo-stream",
            body: {
                chat_id: chatId
            }
        });
    }

    toggleRightSidebar() {
        let rightSidebar = document.getElementById('user-area--chat-files-wrapper')
        let arrowOpen = document.getElementById('user-area--chat-files-arrow-open')
        let arrowClose = document.getElementById('user-area--chat-files-arrow-close')
        if (window.getComputedStyle(arrowOpen.parentElement).display === 'none') {
            rightSidebar.classList.toggle("hidden");
        } else {
            rightSidebar.classList.toggle("closed");
            arrowOpen.classList.toggle('hidden')
            arrowClose.classList.toggle('hidden')
        }
    }

    disconnect() {
        this.channel.unsubscribe()
    }
}
