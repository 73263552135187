import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["formContainer"];
    connect() {
        document.addEventListener('click', this.outsideClick.bind(this))
        this.element.querySelector('#chat_title').focus()
        this.element.querySelector('#chat_title').select()
    }

    outsideClick(event) {
        if (event.target.id !==  this.element.querySelector('#chat_title').id) {
            this.submitForm(event)
        }
    }

    submitForm(event) {
        event.preventDefault(); // Prevent the default form submission

        // Find the first form inside the target container
        const firstForm = this.formContainerTarget.querySelector('form');
        if (!firstForm) return; // If no form is found, exit the function
        firstForm.requestSubmit()
    }
}
