import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs';
import {post, put, destroy} from '@rails/request.js'

export default class extends Controller {
    connect() {
        this.setFileIds()
        this.sortableFiles = document.getElementById('items')
        if (this.sortableFiles) {
            this.sortable = Sortable.create(this.sortableFiles, {
                handle: '.movable-icon',
                group: 'sortableFiles',
                animation: 300,
                ghostClass: 'opacity-0',
                onEnd: (evt) => {
                    this.setFileIds()
                    this.checkTokens(this.getFileIds())
                }
            })
        }
        this.chatIdValue = document.getElementById('user-area--main-section--right-sidebar-chat-files-form').getAttribute('data-chat-id')
    }

    setFileIds() {
        let chatFileIdsElement = document.getElementById('chat_chat_file_ids')
        chatFileIdsElement.value = JSON.stringify(this.getFileIds())
    }

    getFileIds() {
        let itemsElement = document.getElementById('items');
        let items = itemsElement ? itemsElement.children : [];
        let fileIds = []
        for (let i = 0; i < items.length; i++) {
            fileIds.push(items[i].getAttribute('data-components--user-area--main-section--right-sidebar--updatable-content--chat-files-form-file-id-value'))
        }
        return fileIds
    }

    async checkTokens(fileIds) {
        await put(`/user_area/chats/${this.chatIdValue}`, {
            responseKind: "turbo-stream",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                chat_file_ids: fileIds
            })
        })
    }

    async removeFile(e) {
        e.preventDefault()

        let wrapper = e.currentTarget.closest('.item-wrapper')
        let chatFileId = wrapper.querySelector("input[name*='chat_files_attributes'][name*='[id]']").value
        wrapper.remove()

        await destroy(`/user_area/chat_files/${chatFileId}`,  {
            responseKind: "turbo-stream",
            body: JSON.stringify({
                chat_file_ids: this.getFileIds()
            })
        })
    }
}
