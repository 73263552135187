import {Controller} from "@hotwired/stimulus"
import {enter, leave} from "el-transition";

export default class extends Controller {
    static targets = ["menuMobile", "menuBackdrop", "menu", "sidebar", "arrowOpen", "arrowClosed"]

    connect() {
        document.addEventListener('click', this.outsideClick.bind(this))
        document.addEventListener("hide:sidebar_mobile", this.receiveEvent.bind(this));
    }

    toggleSidebar() {
        this.sidebarTarget.classList.toggle("closed");
        if (this.sidebarTarget.classList.contains("closed")) {
            this.arrowClosed()
        } else {
            this.arrowOpen()
        }
    }

    arrowOpen() {
        this.arrowOpenTarget.classList.remove('hidden')
        this.arrowClosedTarget.classList.add('hidden')
    }

    arrowClosed() {
        this.arrowOpenTarget.classList.add('hidden')
        this.arrowClosedTarget.classList.remove('hidden')
    }

    showSidebar() {
        this.menuMobileTarget.classList.remove("hidden");
        enter(this.menuBackdropTarget);
        enter(this.menuTarget);
    }

    hideSidebar() {
        Promise.all([
            leave(this.menuBackdropTarget),
            leave(this.menuTarget),
        ]).then(() => {
            this.menuMobileTarget.classList.add("hidden");
        });
    }

    receiveEvent(event) {
        this.hideSidebar()
    }

    outsideClick(event) {
        if (!this.element.contains(this.menuTarget) && !this.menuMobileTarget.classList.contains('hidden')) {
            this.hideSidebar();
        }
    }
}
