import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['modalWrapper']

    connect() {
        document.addEventListener('click', this.outsideClick.bind(this))
        const event = new CustomEvent("closeChatSettings", {
            bubbles: true,
            cancelable: true,
        });

        document.dispatchEvent(event);
        document.getElementById('user-area--chat-files-modal').classList.add('invisible', 'pointer-events-none')
    }

    outsideClick(event) {
        if (!this.element.contains(event.target) && this.hasModalWrapperTarget) {
            this.modalWrapperTarget.remove()
            document.getElementById('user-area--chat-files-modal').classList.remove('invisible', 'pointer-events-none')
        }
    }
}
