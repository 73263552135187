import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs';
import {post, put, destroy} from '@rails/request.js'

export default class extends Controller {
    connect() {
        // this.setFileIds()
        this.chatIdValue = document.getElementById('user-area--main-section--right-sidebar-chat-files-form').getAttribute('data-chat-id')
    }

    setFileIds() {
        let scenarioFileIdsElement = document.getElementById('scenario_scenario_file_ids')
        scenarioFileIdsElement.value = JSON.stringify(this.getFileIds())
    }

    getFileIds() {
        let itemsElement = document.getElementById('items');
        let items = itemsElement ? itemsElement.children : [];
        let fileIds = []
        for (let i = 0; i < items.length; i++) {
            fileIds.push(items[i].getAttribute('data-components--user-area--settings--scenarios--scenario-files--scenario-files-file-id-value'))
        }
        return fileIds
    }

    async removeFile(e) {
        e.preventDefault()

        let wrapper = e.currentTarget.closest('.item-wrapper')
        let scenarioFileId = wrapper.querySelector("input[name*='scenario_files_attributes'][name*='[id]']").value
        wrapper.remove()

        await destroy(`/user_area/settings/scenario_files/${scenarioFileId}`,  {
            responseKind: "turbo-stream",
            body: JSON.stringify({
                scenario_ids: this.getFileIds()
            })
        })
    }
}
