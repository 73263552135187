import { Controller } from "@hotwired/stimulus"
import {get, post} from "@rails/request.js"

export default class extends Controller {
    static targets = [ "file", "button", "dropZone", "fileUploadTemplate", "uploadedFiles" ]

    onChange(event) {
        this.files = this.fileTarget.files;
        this.submit(event);
    }

    async submit(event) {
        event.preventDefault();
        const form = this.fileTarget.closest('form');
        let chatId = document.getElementById('user-area--main--content-wrapper').getAttribute('data-chat-id');

        // Extract non-file form fields into a plain object
        const formElements = form.elements;
        const otherFormData = {};
        for (let i = 0; i < formElements.length; i++) {
            let element = formElements[i];
            if (element.name && element.type !== 'file' && element.value !== "") {
                otherFormData[element.name] = element.value;
            }
        }


        if(chatId === null) {
            const response = await post(`/user_area/chats/`, {
                responseKind: "json", // Change this to 'json' to indicate you expect a JSON response
                headers: {
                    'Content-Type': 'application/json'
                },
                body: {
                    title: this.fileTarget.files[0].name.substring(0, 30)
                }
            });

            if (response.ok) {
                const data = await response.response.json();
                chatId = data.chat_id;

                await get(`/user_area/chats/${chatId}?only_chat=true`, {responseKind: "turbo-stream"});
            } else {
                console.error('Failed to create chat', response.statusText);
            }
        }



        // Loop through each file and submit them individually
        await Array.from(this.fileTarget.files).forEach((file, index) => {
            const formData = new FormData();

            // Append the non-file fields to each FormData instance
            for (let key in otherFormData) {
                formData.append(key, otherFormData[key]);
            }

            // Append the file to FormData instance
            const name = this.fileTarget.name.replace('[]', '');
            formData.append(name, file);

            // Append chat_id if present
            if (chatId) {
                formData.append('chat_id', chatId);
            }

            let identifier = this.timestampString();
            formData.append('identifier', identifier);

            const cloneDiv =  document.getElementById('file-upload-template').cloneNode(true);
            cloneDiv.classList.remove('hidden');
            cloneDiv.id = `uploaded-file-${identifier}`;
            let title = cloneDiv.querySelector('#uploaded-file-id');
            let description = cloneDiv.querySelector('#uploaded-file-description');
            title.innerText = file.name;
            const fileSizeInMB = file.size / (1024 * 1024); // Convert size to MB
            const fileSizeInKB = file.size / 1024; // Convert size to KB

            let displaySize;
            if (fileSizeInMB >= 1) {
                displaySize = `${fileSizeInMB.toFixed(1)} MB`; // Display size in MB if it's 1.0 or greater
            } else {
                displaySize = `${fileSizeInKB.toFixed(1)} KB`; // Otherwise, display size in KB
            }

            description.innerText = displaySize;
            document.getElementById('uploaded-files').appendChild(cloneDiv);
            this.uploadFile(formData, form.action, cloneDiv, identifier, form);
        });
    }

    uploadFile(formData, action, cloneDiv, identifier, form) {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', action, true);

        let progressBar = cloneDiv.querySelector('#uploaded-file-progress-bar');
        xhr.upload.addEventListener("progress", event => {
            if (event.lengthComputable) {
                const percentComplete = (event.loaded / event.total) * 100;
                progressBar.style.width = `${percentComplete}%`;
            }
        });

        xhr.onload = () => {
            if (xhr.status === 200) {
                Turbo.renderStreamMessage(xhr.responseText);
            } else {
            }
        };

        xhr.setRequestHeader('X-CSRF-Token', document.querySelector('[name="csrf-token"]').content);
        xhr.setRequestHeader('Accept', 'text/vnd.turbo-stream.html'); // Ensure Turbo Stream response
        xhr.send(formData);
    }

    timestampString () {
        return new Date().getTime().toString()
    }

    dragOver(event){
        event.preventDefault()
        this.dropZoneTarget.classList.add('border-2', 'border-dashed', 'border-inline', 'cursor-pointer')
    }

    dragLeave(){
        this.dropZoneTarget.classList.remove('border-2', 'border-dashed', 'border-inline', 'cursor-pointer')
    }

    drop(event){
        event.preventDefault()

        this.dragLeave()
        const files = event.dataTransfer.files
        this.updateButton(this.dropZoneTarget.getAttribute('data-selected-styles'))
    }


    updateButton(selectedStyles) {
        if(selectedStyles) {
            selectedStyles = selectedStyles.split(' ')
            for (let i = 0; i < selectedStyles.length; i++) {
                this.buttonTarget.classList.add(selectedStyles[i])
            }
        }
    }
}
