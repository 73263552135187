import { Controller } from "@hotwired/stimulus"
import consumer from "@/channels/consumer"
import { get } from '@rails/request.js'

export default class extends Controller {

    connect() {
        this.channel = consumer.subscriptions.create("ChatChannel", {
            async received(data) {
                await get(`/user_area/chats?chat_id=${data.chat_id}`, { responseKind: "turbo-stream" })
            }
        });
    }
}
