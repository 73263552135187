import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["toolTipAnchor", "toolTip"]
    static values = { identifier: String }

    setTooltipPosition() {
        let toolTip = this.toolTipTarget.cloneNode(true)
        let element = document.getElementById('tooltip-anchor').appendChild(toolTip);
        const tooltipAnchorRect = this.toolTipAnchorTarget.getBoundingClientRect()

        const top = tooltipAnchorRect.top; // Add scrollY for accurate positioning if scrolled
        const left = tooltipAnchorRect.left;

        element.style.position = 'absolute';
        element.style.zIndex = '1000';
        element.style.top = `${top - 36}px`;
        element.classList.remove('hidden')
        element.style.left = `${left + ((this.toolTipAnchorTarget.clientWidth - element.clientWidth) / 2)}px`;
        element.id = `tooltip--${this.identifierValue}`
    }

    displayTooltip(event) {
        const tooltipId = `tooltip--${this.identifierValue}`;
        const tooltipElement = document.getElementById(tooltipId);
        if (tooltipElement) {
            tooltipElement.classList.remove('hidden');
        } else {
            this.setTooltipPosition()
        }
    }

    hideTooltip(event) {
        const tooltipId = `tooltip--${this.identifierValue}`;
        const tooltipElement = document.getElementById(tooltipId);
        if (tooltipElement) {
            tooltipElement.classList.add('hidden');
        }
    }


}
