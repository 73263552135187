import { Controller } from "@hotwired/stimulus"
import IMask from 'imask'

export default class extends Controller {
    static targets = [ "input" ]

    connect() {
        this.maskOptions = {
            mask: Number,
            scale: 0,
            radix: '.',
            mapToRadix: ['.'],
            normalizeZeros: true,
            padFractionalZeros: true
        }

        if(this.inputTarget.min) {
            this.maskOptions.min = this.inputTarget.min
        }

        if(this.inputTarget.max) {
            this.maskOptions.max = this.inputTarget.max
        }

        this.mask = IMask(this.inputTarget, this.maskOptions)
    }
}
