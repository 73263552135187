import { Controller } from "@hotwired/stimulus";
import Sortable from 'sortablejs';
import { get, post, destroy } from '@rails/request.js';

export default class extends Controller {
    connect() {
        this.setFileIds();
        this.sortableFiles = document.getElementById('items');
        if (this.sortableFiles) {
            this.sortable = Sortable.create(this.sortableFiles, {
                handle: '.movable-icon',
                group: 'sortableFiles',
                animation: 300,
                ghostClass: 'opacity-0',
            });
        }
        this.chatIdValue = document.getElementById('user-area--main-section--right-sidebar-chat-files-form').getAttribute('data-chat-id');
    }

    setFileIds() {
        let chatFileIdsElement = document.getElementById('chat_chat_file_ids');
        chatFileIdsElement.value = JSON.stringify(this.getFileIds());
    }

    getFileIds() {
        let itemsElement = document.getElementById('items');
        let items = itemsElement ? itemsElement.children : [];
        let fileIds = [];
        for (let i = 0; i < items.length; i++) {
            fileIds.push(items[i].getAttribute('data-components--user-area--main-section--right-sidebar--updatable-content--chat-files-form-file-id-value'));
        }
        return fileIds;
    }

    async uploadFiles(event) {
        event.preventDefault();
        this.fileTarget = document.getElementById('scenario_scenario_files_attributes_NEW_RECORD_attachment');

        if(!this.fileTarget) {
            this.fileTarget = document.getElementById('scenario_scenario_files_attachment')
        }

        const form = this.fileTarget.closest('form');
        const actionUrl = '/user_area/settings/scenario_file/';

        const formElements = form.elements;
        const otherFormData = {};
        for (let i = 0; i < formElements.length; i++) {
            let element = formElements[i];
            if (element.name && element.type !== 'file' && element.value !== "") {
                otherFormData[element.name] = element.value;
            }
        }

        let scenarioId = await this.getScenarioId()

        await Promise.all(Array.from(this.fileTarget.files).map((file) => {
            const formData = new FormData();
            for (let key in otherFormData) {
                formData.append(key, otherFormData[key]);
            }
            const name = this.fileTarget.name.replace('[]', '');
            formData.append(name, file);

            if (scenarioId) {
                formData.append('scenario_id', scenarioId);
            }

            let identifier = this.timestampString();
            formData.append('identifier', identifier);

            // Clone the template for upload progress
            const uploadDiv = this.cloneUploadTemplate(identifier, file);
            document.getElementById('uploading-scenario-files').appendChild(uploadDiv);

            return this.uploadFile(formData, actionUrl, uploadDiv, identifier, scenarioId);
        }));
        this.showFiles(scenarioId)
    }

    async getScenarioId() {
        let scenarioIdElement = document.getElementById('scenario_scenario_id')
        const response = await post(`/user_area/settings/scenario_file/`, {
            responseKind: "json", // Change this to 'json' to indicate you expect a JSON response
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                scenario_id: scenarioIdElement.value
            }
        });
       if (response.ok) {
           const body = await response.text
           const data = JSON.parse(body); // Parse it to a JavaScript object

           // Access the scenario_id from the parsed object
           const scenarioIdFromResponse = data.scenario_id;
           document.getElementById('scenario_scenario_id').value = scenarioIdFromResponse
           return scenarioIdFromResponse
       }
    }

    async showFiles(scenarioId) {
       await get(`/user_area/settings/scenario_file/`, {
            responseKind: "turbo-stream",
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                scenario_id: scenarioId
            }
        });
    }

    cloneUploadTemplate(identifier, file) {
        const cloneDiv =  document.getElementById('file-upload-template').cloneNode(true);
        cloneDiv.classList.remove('hidden');
        cloneDiv.id = `uploaded-file-${identifier}`;
        let title = cloneDiv.querySelector('#uploaded-file-id');
        let description = cloneDiv.querySelector('#uploaded-file-description');
        title.innerText = file.name;
        const fileSizeInMB = file.size / (1024 * 1024); // Convert size to MB
        const fileSizeInKB = file.size / 1024; // Convert size to KB

        let displaySize;
        if (fileSizeInMB >= 1) {
            displaySize = `${fileSizeInMB.toFixed(1)} MB`; // Display size in MB if it's 1.0 or greater
        } else {
            displaySize = `${fileSizeInKB.toFixed(1)} KB`; // Otherwise, display size in KB
        }

        description.innerText = displaySize;
        return cloneDiv;
    }

    uploadFile(formData, actionUrl, uploadDiv, identifier, scenarioId) {
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', actionUrl, true);

        xhr.upload.addEventListener("progress", event => {
            if (event.lengthComputable) {
                const percentComplete = (event.loaded / event.total) * 100;
                console.log(percentComplete)
            }
        });

        xhr.onload = () => {
            if (xhr.status === 200) {
                // Optionally update UI or hide the progress bar
                setTimeout(() => {
                    uploadDiv.remove(); // Remove or hide the progress bar after a delay
                }, 2000); // Wait 2 seconds before removing
                Turbo.renderStreamMessage(xhr.responseText);
            } else {
                console.error('Failed to upload file', xhr.statusText);
            }
        };

        xhr.onerror = () => {
            statusText.innerText = 'Upload Error';
            console.error('Error during file upload');
        };

        xhr.setRequestHeader('X-CSRF-Token', document.querySelector('[name="csrf-token"]').content);
        xhr.setRequestHeader('Accept', 'text/vnd.turbo-stream.html');
        xhr.send(formData);
    }

    timestampString() {
        return new Date().getTime().toString();
    }

    async removeFile(e) {
        e.preventDefault();

        let wrapper = e.currentTarget.closest('.item-wrapper');
        let identifier = wrapper.getAttribute('data-components--user-area--settings--scenarios--form-content-file-id-value')
        wrapper.remove();

        await destroy(`/user_area/settings/scenario_file/`, {
            responseKind: "turbo-stream",
            body: JSON.stringify({
                identifier: identifier
            })
        });
    }

    submitForm(form) {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', form.action, true);
        xhr.setRequestHeader('X-CSRF-Token', document.querySelector('[name="csrf-token"]').content);
        xhr.setRequestHeader('Accept', 'text/vnd.turbo-stream.html');

        xhr.onload = () => {
            if (xhr.status === 200) {
                Turbo.renderStreamMessage(xhr.responseText);
            } else {
                console.error('Form submission failed:', xhr.statusText);
            }
        };

        const formData = new FormData(form);
        xhr.send(formData);
    }
}
