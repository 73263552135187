import { Controller } from "@hotwired/stimulus"
import {post, put} from '@rails/request.js'

export default class extends Controller {
    connect() {
        this.chatIdValue = document.getElementById('user-area--main-section--right-sidebar-chat-files-form').getAttribute('data-chat-id')
    }

    async onSelect(e) {
        let id = e.currentTarget.id
        let parts = id.split('_')
        if(this.chatIdValue !== null) {
            await put(`/user_area/chats/${this.chatIdValue}`, {
                responseKind: "turbo-stream",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    chat: {
                        response_language: parts[parts.length - 1]
                    }
                })
            })
        } else {
            await post(`/user_area/chats`, {
                responseKind: "turbo-stream",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    response_language: parts[parts.length - 1]
                })
            })
        }
    }
}
