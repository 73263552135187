import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = { isEagerLoaded: Boolean, enableBackdropClick: Boolean}
    static targets = ["modalWithBackdrop"]

    connect() {
        if(this.isEagerLoadedValue === true) {
            this.toggleModal()
        }
    }

    onBackdropClick(event) {
        // Only trigger if the click is on the targeted element (the backdrop), not on its descendants (the modal)
        if (event.target === event.currentTarget) {
            this.enableBackdropClickValue ? this.closeModal() : this.handleInOtherController();
        }
    }

    toggleModal() {
        this.modalWithBackdropTarget.classList.toggle('hidden')
        const onToggleEvent = new CustomEvent("onModalToggle", {
            bubbles: true
        });

        this.element.dispatchEvent(onToggleEvent)
    }

    closeModal() {
        if (this.enableBackdropClickValue === true) {
            if (this.isEagerLoadedValue === true) {
                this.toggleModal()
            } else {
                this.modalWithBackdropTarget.remove();
            }
        } else {
            this.handleInOtherController();
        }
    }

    handleInOtherController() {
        const clickEvent = new CustomEvent("onModalBackdropClick", {
            bubbles: true
        });

        this.element.dispatchEvent(clickEvent);
    }

}
