import { Controller } from "@hotwired/stimulus"
import IMask from 'imask'

export default class extends Controller {
    static targets = [ "selectedRange", "slider", "input", "stepMarkersContainer", "dropdownContent", "container" ]

    connect() {
        this.container = this.containerTarget
        this.slider = this.sliderTarget
        this.range = this.selectedRangeTarget

        this.maskOptions = {
            mask: Number,
            min: this.inputTarget.min,
            max: this.inputTarget.max,
            scale: 2,
            radix: '.',
            mapToRadix: ['.'],
            normalizeZeros: true,
            padFractionalZeros: true
        }

        this.mask = IMask(this.inputTarget, this.maskOptions)

        this.updateSliderStyle()
        this.onSliderChange()
        this.hideDropdown()
    }

    onSliderChange() {
        this.inputTarget.value = this.slider.value
        this.updateSliderStyle()
    }

    onInputChange() {
        this.slider.value = this.inputTarget.value
        this.updateSliderStyle()
    }

    updateSliderStyle() {
        const sliderValue = this.slider.value;
        const max = this.slider.max;
        const percentage = (sliderValue / max) * 100;
        this.range.style.left = "0%";
        this.range.style.right = `${100 - percentage}%`;
    }


    showDropdown() {
        let existingDropdown = document.getElementById('active-dropdown');
        if(existingDropdown) return
        let dropdownContent = this.dropdownContentTarget.cloneNode(true)
        let element = document.body.appendChild(dropdownContent);
        const buttonRect = this.element.getBoundingClientRect();
        const top = buttonRect.bottom + window.scrollY;
        const left = buttonRect.left + window.scrollX;

        element.style.position = 'absolute'; element.style.zIndex = '1000';
        element.classList.remove("hidden");
        element.style.top = `${top - (element.offsetHeight / 2 + this.element.offsetHeight / 2)}px`;
        element.style.left = `${left - element.offsetWidth - 12}px`;
        element.id = 'active-dropdown'
    }

    hideDropdown() {
        let element = document.getElementById('active-dropdown');

        if (element) {
            document.body.removeChild(element);
        }
    }
}
