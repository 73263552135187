import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["contentContainer", "arrowUp", "arrowDown"];
    static values = {isToggled: Boolean};

    connect() {
        if (this.isToggledValue === true) {
            this.onClick()
        }
    }


    onClick() {
        this.contentContainerTarget.classList.toggle('hidden')
        this.arrowUpTarget.classList.toggle('hidden')
        this.arrowDownTarget.classList.toggle('hidden')
    }
}