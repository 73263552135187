import { Controller } from "@hotwired/stimulus"
import {enter, leave} from "el-transition";
import {post} from '@rails/request.js'

export default class extends Controller {
    static targets = ["messageWrapper"]

    connect() {
        this.newChatWidget = document.getElementById('new--chat--example--widget')
        if(this.newChatWidget) {
            enter(this.newChatWidget)
        }

        this.scrollNewestMessageIntoView()
        this.hideSidebarMobile()
    }

    hideSidebarMobile() {
        const event = new CustomEvent("hide:sidebar_mobile")
        document.dispatchEvent(event);
    }

    scrollNewestMessageIntoView() {
        let messageWrapper = document.getElementById('user-area--main--chat-messages-wrapper');

        setTimeout(() => {
            messageWrapper.scrollTo({
                top: messageWrapper.scrollHeight,
                behavior: 'smooth'
            });
        }, 100);
    }

    copyText(element) {
        const chat_message = element.target.closest('.chat-message')
        const span = chat_message.querySelector('span:first-child')
        navigator.clipboard.writeText(span.innerText) //only working with https
    }

    async submitMessage(event) {
        leave(this.newChatWidget).then(() => {
            document.getElementById('existing--chat-messages-wrapper').remove()
        })

        await post(`/user_area/chat_messages`,  {
            responseKind: "turbo-stream",
            body: {
                chat_message: {
                    message: event.target.innerText
                }
            }
        })
    }

    disconnect() {
        localStorage.removeItem("inputValue") //remove inputValue item when switching between chats.
    }
}
